import "./App.css";
// import LoginPage from "./LoginPage";
// import FrontPage from "./FrontPage";
import Header from "./Header";


function App() {

  // const userIsLogedIn = false
    return (
      <div className="app">
        <Header/>
        {/* <div className="App-header">
          {userIsLogedIn ? <FrontPage/> : <LoginPage></LoginPage>}
        </div> */}
      </div>
    );
  
}

export default App;
