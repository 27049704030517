import React, { useEffect } from 'react';
import "./Header.css"

function Header() {
    const server = "http://http://ec2-13-41-159-151.eu-west-2.compute.amazonaws.com";
    // const [cookies, setCookie] = useCookies(); // getting react hooks
    const [logButton, setLogButton] = React.useState("");

    useEffect(() => {
        fetch(server + "/secret", {
            method: 'GET',
            credentials: 'include',
            mode: 'cors'
        }).then(setLogButton("Login")).catch((_) => {setLogButton("Logout")})
    }, [])

    const logio = async () => {
        if (logButton === "Login") {
            await fetch(server + "/login", {
                method: 'GET',
                credentials: 'include',
                mode: 'cors'
              }).catch(e => console.log(e));
            // console.log(await resp.headers.get("set-cookie"));
            // console.log(resp);
            // resp.headers.forEach(console.log);
            // console.log(document.cookie);
            setLogButton("Logout");
        } else {
            await fetch(server + "/logout", {
                method: 'GET',
                credentials: 'include',
                // mode: 'cors'
              }).catch(e => console.log(e));
            //   console.log(await resp.text());
  
            setLogButton("Login");
        }
    }

    const getSecret = async () => {
        console.log(document.cookie)
        let resp = await fetch(server + "/secret", {
            method: 'GET',
            credentials: 'include',
            mode: 'cors'
          })
        alert(await resp.text())
    }


    return (
        <div className='header'>
            <div className='header__left'>
                <img src="https://upload.wikimedia.org/wikipedia/commons/3/34/Home-icon.svg" alt="https://upload.wikimedia.org/wikipedia/commons/3/34/Home-icon.svg"/>
            </div>
            <div className='header__center'>Welcome to <code>Unstable Array</code></div>
            <div className='header__right'>
                <button onClick={logio}>{logButton}</button>
                <button onClick={getSecret}>secret</button>
            </div>
        </div>
    );
}

export default Header;